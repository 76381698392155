import _reactIsDevelopment from "./cjs/react-is.development.js";
export { _reactIsDevelopment as default };
export const AsyncMode = _reactIsDevelopment.AsyncMode,
      ConcurrentMode = _reactIsDevelopment.ConcurrentMode,
      ContextConsumer = _reactIsDevelopment.ContextConsumer,
      ContextProvider = _reactIsDevelopment.ContextProvider,
      Element = _reactIsDevelopment.Element,
      ForwardRef = _reactIsDevelopment.ForwardRef,
      Fragment = _reactIsDevelopment.Fragment,
      Lazy = _reactIsDevelopment.Lazy,
      Memo = _reactIsDevelopment.Memo,
      Portal = _reactIsDevelopment.Portal,
      Profiler = _reactIsDevelopment.Profiler,
      StrictMode = _reactIsDevelopment.StrictMode,
      Suspense = _reactIsDevelopment.Suspense,
      isAsyncMode = _reactIsDevelopment.isAsyncMode,
      isConcurrentMode = _reactIsDevelopment.isConcurrentMode,
      isContextConsumer = _reactIsDevelopment.isContextConsumer,
      isContextProvider = _reactIsDevelopment.isContextProvider,
      isElement = _reactIsDevelopment.isElement,
      isForwardRef = _reactIsDevelopment.isForwardRef,
      isFragment = _reactIsDevelopment.isFragment,
      isLazy = _reactIsDevelopment.isLazy,
      isMemo = _reactIsDevelopment.isMemo,
      isPortal = _reactIsDevelopment.isPortal,
      isProfiler = _reactIsDevelopment.isProfiler,
      isStrictMode = _reactIsDevelopment.isStrictMode,
      isSuspense = _reactIsDevelopment.isSuspense,
      isValidElementType = _reactIsDevelopment.isValidElementType,
      typeOf = _reactIsDevelopment.typeOf;